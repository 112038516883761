<div class="container">
    <div class="title">Urlaub & Aktuelles</div>
    <div class="text">
        <div class="spacer-small"></div>
        <div class="spacer"></div>
        <div>
            <div class="red-text" *ngFor="let text of urlaubsList; let i = index">
                <div *ngIf="i > 0" class="spacer-line"></div>
                <div [innerHTML]="text"></div>
            </div>
        </div>
        <div class="spacer-end"></div>
    </div>
</div>