<div class="container">
    <div class="title">Übersicht unserer Leistungen</div>
    <div class="text">
        <div class="spacer-small"></div>
        Die Praxis "Gesundheitsportal Dr. Schnell" bietet Ihnen alle hausärztlichen Leistungen auf hohem
        fachinternistischem Niveau.<br><br>
        Durch kontinuierliche Fort- und Weiterbildung wird das Leistungsspektrum ständig verbessert und
        erweitert.<br><br>
        Auf den folgenden Seiten werden Ihnen die Leistungsangebote der Praxis näher vorgestellt.<br><br>
        <div class="spacer-small"></div>
        <ul>
            <li><a href="/vorsorge">Vorsorgeuntersuchungen</a></li>
            <li><a href="/ekg">EKG</a></li>
            <li><a href="/lungenfunktion">Lungenfunktion</a></li>
            <li><a href="/impfungen">Impfungen</a></li>
            <li><a href="/checkup">Check-Up</a></li>
            <li><a href="/ultraschall">Ultraschall</a></li>
            <li><a href="/labor">Laboruntersuchungen</a></li>

        </ul>
    </div>
</div>