import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-office-hours-view',
  templateUrl: './office-hours-view.component.html',
  styleUrls: ['./office-hours-view.component.scss']
})
export class OfficeHoursViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
