<div class="container">
    <div class="title">Lungenfunktion</div>
    <div class="text">
        <div class="spacer-small"></div>
        Die Lungenfunktion (Spirometrie) dient zum Nachweis von akuten oder chronischen Atemwegserkrankungen wie z.B
        Asthma oder Raucherhusten. Aus Vorsorgegründen (s. Check-up), als Voraussetzung zur Taucheignung oder einfach
        aus medizinischem Interesse (wie groß ist eigentlich mein Lungenvolumen?) wird diese einfach durchzuführende
        Untersuchung elektronisch in unserer Praxis durchgeführt
        <br><br>
        Ein Mundstück wird hierbeit einem Registriergerät verbunden, welches dann die Atembewegungen aufzeichnet.
        <br><br>
        Diese Untersuchung ist auch Voraussetzung für das von den gesetzlichen Krankenkassen zertifizierte
        desease-management Programm (sog. DMP) für chronische Lungenerkrankungen, das in unserer Praxis durchgeführt
        wird.
        <div class="spacer-end"></div>
    </div>
</div>