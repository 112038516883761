import { Component, OnInit } from '@angular/core';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent implements OnInit {
  pictures = [
    { id: 0, path: '/assets/images/BildName.jpg' },
    { id: 1, path: '/assets/images/BildPraxis1.JPG' },
    { id: 2, path: '/assets/images/BildPraxis2.JPG' },
    { id: 3, path: '/assets/images/BildPraxis3.JPG' },
    { id: 4, path: '/assets/images/BildPraxis4.JPG' },
    { id: 5, path: '/assets/images/BildPraxis5.JPG' },
    { id: 6, path: '/assets/images/BildPraxis6.JPG' },
    { id: 7, path: '/assets/images/BildPraxis7.JPG' }
  ]
  currentPicture: any;
  aktuellesList: any[];

  constructor(private textService: TextService) {
    this.currentPicture = this.pictures[0];
    this.slidePicture()
  }

  ngOnInit(): void {
    this.textService.getAktuellesList().snapshotChanges().subscribe(list =>
     {
      this.aktuellesList = list.map(item => {return item.payload.val()});
     });
  }

  slidePicture() {
    setTimeout(() => {
      if (this.currentPicture.id === 7) {
        this.currentPicture = this.pictures[0];
      } else {
        this.currentPicture = this.pictures[this.currentPicture.id + 1];
      }
      this.slidePicture();
    }
      , 5000);
  }

  nextPicture(){
    if (this.currentPicture.id === 7) {
      this.currentPicture = this.pictures[0];
    } else {
      this.currentPicture = this.pictures[this.currentPicture.id + 1];
    }
  }

  previousPicture(){
    if (this.currentPicture.id === 0) {
      this.currentPicture = this.pictures[7];
    } else {
      this.currentPicture = this.pictures[this.currentPicture.id - 1];
    }
  }

}
