<div class="container">
    <div class="title">Ultraschall</div>
    <div class="text">
        <div class="spacer-small"></div>
        Die Untersuchung mittels Ultraschall (Sonographie) ist aus dem hausärztlichen und besoners auf dem
        internistischen Alltag nicht mehr weg zu denken. Auf unkompliziertem Weg können ohne jegliche Nebenwirkungen
        wichtige Einblicke in den menschlichen Körper gewonnen werden und damit unverzichtbare Hinweise auf Ursachen und
        Behandlungsmethoden gewonnen werden.
        <br><br>
        Hierzu bedarf es zum einen eines modernen Ultraschallgerätes und zum anderen eines erfahrenen Untersuchers.
        <div class="spacer"></div>
        In unserer Praxis vereinigt sich beides:
        <br><br>
        Es steht ein modernes Ultraschallgerät des renommierten Medizingeräte-Herstellers General Electronic zur
        Verfügung. Die Erfahrung des Untersuchers beweist sich aus einer fundierten klinischen Ausbildung von ca.1000
        Stunden unter Aufsicht und Kontrolle von Ausbildern der deutschen Gesellschaft für Ultraschall (www.degum.de)
        sowie einer durchgeführten Untersuchungsanzahl von mittlerweile über 10.000 Untersuchungen. Diese hohe Qualität
        auf fachinternistischem Niveau wurde von der Landesärztekammer urkundlich bestätigt.
        <div class="spacer"></div>
        <b class="orange-bold">Es können folgende Organe untersucht werden:</b>
        <br><br>
        <ul>
            <li>Halsgefäße <a href="/checkup">Check-Up</a></li>
            <li>Nieren und ableitende Harnwege</li>
            <li>übrige Bauchorgane (Leber, Gallenblase, Milz, etc)</li>
            <li>Lymphknoten</li>
            <li>Schilddrüse</li>
            <li>Gefäße (v.a. Beinvenen zum Thromboseausschluß)</li>
            <li>Prostata</li>
        </ul>
        <div class="spacer-end"></div>
    </div>
</div>