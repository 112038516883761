import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impfungen-view',
  templateUrl: './impfungen-view.component.html',
  styleUrls: ['./impfungen-view.component.scss']
})
export class ImpfungenViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
