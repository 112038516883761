import { Component, OnInit } from '@angular/core';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-urlaub-view',
  templateUrl: './urlaub-view.component.html',
  styleUrls: ['./urlaub-view.component.scss']
})
export class UrlaubViewComponent implements OnInit {

  urlaubsList: any[];

  constructor(private textService: TextService) { }

  ngOnInit(): void {
    this.textService.getUrlaubList().snapshotChanges().subscribe(list =>
      {
       this.urlaubsList = list.map(item => {return item.payload.val()});
      });
  }

}
