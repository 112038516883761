// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCG95bXpSal72ctxo8jZdSCCaQZthuBFdw",
    authDomain: "dr-schnell-wehrheim.firebaseapp.com",
    databaseURL: "https://dr-schnell-wehrheim-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dr-schnell-wehrheim",
    storageBucket: "dr-schnell-wehrheim.appspot.com",
    messagingSenderId: "405302586135",
    appId: "1:405302586135:web:95f9124fa83749d7c36552"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
