<div class="container">
    <div class="title">EKG</div>
    <div class="text">
        <div class="spacer-small"></div>
        Mit der Ableitung von elektrischen Impulsen des Herzens kann ein <b>Elektrokardiogramm</b> (EKG) aufgezeichnet werden.
        Diese bekannte Verfahren, welches bei uns computergestützt direkt auf dem Monitor dargestellt und elektronisch
        aufgezeichnet wird, dient zur Diagnostik einer Vielzahl von Herzerkrankungen:
        <div class="spacer-small"></div>
        <ul>
        <li>Herzrhythmusstörungen</li>
        <li>Verdickung des Herzmuskels</li>
        <li>Verengung der Herzkrankgefäße</li>
        <li>Herzmuskelentzündung</li>
        </ul>
        <div class="spacer-small"></div>
        Im Bedarfsfall kann in unserer Praxis auch eine EKG über 24 Stunden erfolgen (sog. <b>Langzeit-EKG</b>).
        <br><br>
        Für besondere Fragestellungen oder für eine erweiterte Diagnostik (z.B. Herzkatheter) arbeiten wir mit den
        großen kardiologischen Praxen und den Spezialeinrichtungen der renommierten Krankenhäuser kollegial zusammen und
        können in dringenden Fällen direkt mit dem zuständigen Facharzt einen Termin vereinbaren.
        <br><br>
        In akuten Fällen ist ein unverzügliches Handeln in unserer Praxis garantiert !
        <div class="spacer-end"></div>
    </div>
</div>