<div class="container">
    <div class="title">Check-Up</div>
    <div class="text">
        <div class="spacer-small"></div>
        Oft werden aus verschiedenen Anlässen Untersuchungen gewünscht, die von den gesetzlichen Krankenkassen nicht
        übernommen werden. Sporttauglichkeits- oder Tauchuntersuchungen, Fahrtüchtigkeits- oder Reiseatteste gehören
        hierzu.
        <br><br>
        Sehr wichtig und medizinisch überaus sinnvoll sind auch erweiterte Gesundheits-checks. Sie stellen eine
        medizinisch sinnvolle Ergänzung zur Vorsorge- und Tumoruntersuchung der gesetzlichen Krankenkassen dar, die in
        der Regel privat als Selbstzahlerleistung oder von den privaten Kassen übernommen werden.
        <br><br>
        Dieses erweiterte Vorsorgeprogramm sollte vor der Untersuchung mit uns in der Praxis besprochen werden, damit
        Ihre spezielle Situtation entsprechend gewürdigt werden kann und Sie auch über die Kosten infomiert sind.
        <div class="spacer"></div>
        <b class="orange-bold">Labor-check</b>
        <br><br>
        <ul>
            <li>Leber-/Nierenwerte</li>
            <li>Eisen</li>
            <li>Eiweiße und Abwehrstoffe</li>
            <li>Kalzium</li>
            <li>Kalium</li>
            <li>Magnesium</li>
            <li>großes Blutbild incl. roter und weißer Blutkörperchen</li>
            <li>Auf Wunsch: Vitamin D, Vitamin B12</li>
        </ul>
        <div class="spacer"></div>
        <b class="orange-bold">Facharzt-check</b>
        <br><br>
        Dieses Untersuchungspaket wird auf fachinternistischem Niveau durchgeführt und beinhaltet zusätzlich
        <br><br>
        <ul>
            <li>Große Blutuntersuchung (Blutbild, Leber-/Nierenwerte, Kalzium, Eisen, etc.)</li>
            <li>Lungenfunktionsprüfung</li>
            <li>EKG</li>
            <li>Ultraschalluntersuchung der Nieren und der ableitenden Harnwege</li>
            <li>Ultraschalluntersuchung der übrigen Bauchorgane</li>
            <li>Ultraschalluntersuchung der Schilddrüse</li>
            <li>Bei Männern Prostata-Ultraschalluntersuchung</li>
            <li>ausführliches Abschlußgespräch</li>
        </ul>
        <div class="spacer"></div>
        <b class="orange-bold">Blutuntersuchung</b>
        <br><br>
        Blutuntersuchung bei besonderer Ernährungsform (zB vegetarisch/vegan). Hierbi können frühzeitig eventuelle Mangelzustände erkannt werden
        <div class="spacer"></div>
        <b class="orange-bold">Ultraschall der Halsgefäße</b>
        <br><br>
        Hier kann die Gefäßsituation besonders bei erhöhten Cholesterinwerten ermittelt werden 
        <br><br>
        <div class="spacer"></div>
        Im Anschluß an das Abschlußgespräch erhalten Sie eine detaillierte Rechnung.
        <div class="spacer-end"></div>
    </div>
</div>