import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressum-view',
  templateUrl: './impressum-view.component.html',
  styleUrls: ['./impressum-view.component.scss']
})
export class ImpressumViewComponent implements OnInit {

  email = 'info@gesundheitsportal-dr-schnell.com';

  constructor() { }

  ngOnInit(): void {
  }

  openPdf(): void{
    window.open('/assets/5c14c18f6a616acaffff8008fffffff0.pdf');
  }

}
