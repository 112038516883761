import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrschnellViewComponent } from './pages/drschnell-view/drschnell-view.component';
import { HomeViewComponent } from './pages/home-view/home-view.component';
import { ImpressumViewComponent } from './pages/impressum-view/impressum-view.component';
import { LehreViewComponent } from './pages/lehre-view/lehre-view.component';
import { CheckupViewComponent } from './pages/leistungen-view/checkup-view/checkup-view.component';
import { EkgViewComponent } from './pages/leistungen-view/ekg-view/ekg-view.component';
import { ImpfungenViewComponent } from './pages/leistungen-view/impfungen-view/impfungen-view.component';
import { LaborViewComponent } from './pages/leistungen-view/labor-view/labor-view.component';
import { LeistungenViewComponent } from './pages/leistungen-view/leistungen-view.component';
import { LungenViewComponent } from './pages/leistungen-view/lungen-view/lungen-view.component';
import { UltraschallViewComponent } from './pages/leistungen-view/ultraschall-view/ultraschall-view.component';
import { VorsorgeViewComponent } from './pages/leistungen-view/vorsorge-view/vorsorge-view.component';
import { OfficeHoursViewComponent } from './pages/office-hours-view/office-hours-view.component';
import { TeamViewComponent } from './pages/team-view/team-view.component';
import { UrlaubViewComponent } from './pages/urlaub-view/urlaub-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: 'home', component: HomeViewComponent },
  { path: 'sprechzeiten', component: OfficeHoursViewComponent },
  { path: 'team', component: TeamViewComponent },
  { path: 'drschnell', component: DrschnellViewComponent },
  { path: 'impressum', component: ImpressumViewComponent },
  { path: 'urlaub', component: UrlaubViewComponent },
  { path: 'lehre', component: LehreViewComponent },
  { path: 'leistungen', component: LeistungenViewComponent},
  { path: 'vorsorge', component: VorsorgeViewComponent},
  { path: 'ekg', component: EkgViewComponent},
  { path: 'lungenfunktion', component: LungenViewComponent},
  { path: 'impfungen', component: ImpfungenViewComponent},
  { path: 'checkup', component: CheckupViewComponent},
  { path: 'ultraschall', component: UltraschallViewComponent},
  { path: 'labor', component: LaborViewComponent},
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
