import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public openedMenu: boolean;
  menupoint: string;

  constructor(private route: Router) {
    this.openedMenu = false;
    this.menupoint = 'home'
  }

  ngOnInit(): void {
    let teststring = ''
    teststring = window.location.pathname;
    if(teststring !== '/'){
    this.menupoint = teststring.slice(1,teststring.length);
    }
  }

  openMenu(){
    this.openedMenu = !this.openedMenu;
    console.log(this.openedMenu);
  }

  clickMenu(point: string){
    event.stopPropagation();
    event.preventDefault()
    this.menupoint = point;
    this.openedMenu = false;
  }

}
