<div class="container">
    <div class="title">Lehrpraxis der JWGoethe Universität</div>
    <div class="text">
        <div class="spacer-small"></div>
        <div class="spacer"></div>
        <div>
            Seit mehreren Jahren ist die Praxis als Lehrpraxis der Johann-Wolfgang-Goethe-Universität zertifiziert und
            akkreditiert.
            Mehrmals im Jahr werden Sie daher Studenten in alle Ausbildungsstufen in der Praxis antreffen.<br><br>

            Die Studenten haben hierbei die Möglichkeit, Patienten in einer hausärztlichen Praxis kennen zu lernen,
            diese zu befragen, zu untersuchen und daraufhin mit mir zu besprechen.
            <div class="spacer"></div>
            <div class="picture-box">
                <img class="picture" src="/assets/images/lehrpraxis1.jpg">
            </div>
            <div class="picture-box">
                <img class="picture" src="/assets/images/lehrpraxis2.jpg">
            </div>
        </div>
        <div class="spacer"></div>
        <div class="spacer-orange"></div>
        <div class="spacer"></div>
        <b class="orange-bold">Studentenmeinungen</b><br><br>
        <div>Ich wurde vom Praxisteam sofort integriert. Selbständiges Arbeiten wurde gefördert und viel Wert auf
            direkten Patientenkontakt gelegt.
            Vielen Dank für die tolle Betreuung im Blockpraktikum! (Christine Dreißler, 9. Semester)<br><br>

            Absolut empfehlenswert! Da Dr. Schnell Internist ist, bekommt man ein völlig unselektioniertes Patientengut
            und kann sich super seine eigenen Gedanken machen, was das Beste für den Patienten ist, und wird ermuntert,
            zwischendurch kleine Recherchen zu bestimmten Fragestellungen selbst durchzuführen - das Ganze gedeckelt von
            einer hervorragenden 1:1 Betreuung. Das gesamte Team ist ausgesprochen nett, freundlich und aufgeschlossen,
            man fühlt sich schnell aufgenommen. Ich habe hier zum Ende meines Studiums sehr viel gelernt und würde
            jederzeit wieder in die Praxis von Dr. Schnell und seinem Team gehen! (Serap El-Salhani, 10. Semester)
        </div>
        <div class="spacer-end"></div>
    </div>
</div>