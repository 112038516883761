import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class TextService {

  aktuellesListe: AngularFireList<any>;
  urlaubsListe: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) { }

  getAktuellesList() {
    return this.aktuellesListe = this.firebase.list('Aktuelles');
  }

  getUrlaubList(){
    return this.urlaubsListe = this.firebase.list('Urlaub');
  }
}
