<div class="container">
    <div class="title">Vorsorgeuntersuchungen</div>
    <div class="text">
        <div class="spacer-small"></div>
        Der von den Krankenkasse festgelegte Umfang der Vorsorgeuntersuchung ist alters- und geschlechtsspezifisch
        festgelegt und wird von diesen übernommen. Auf Wunsch kann diese Basisuntersuchung durch spezielle
        Vorsorgeuntersuchungen ergänzt werden, die Sie als Selbstzahler in Anspruch nehmen können <a
            href="/checkup">Check-Up</a>
        <div class="spacer"></div>
        <b class="orange-bold">Jugendschutzuntersuchung ab dem 12. Lebensjahr:</b>
        <br><br>
        Hier wird, meist zusammen mit den Eltern, auf die persönliche und familiäre Entwicklung des Jugendlichen
        eingegangen, es wird der Impfschutz komplettiert und eine auf das Alter abgestimmte körperliche Untersuchung
        vorgenommen. Eine wichtige Untersuchung, da die nächste Vorsorgeuntersuchung erst nach vielen Jahren (s.u.)
        durchgeführt wird.
        <div class="spacer"></div>
        <b class="orange-bold">Zwischen 18 und 34 Jahren:</b>
        <br><br>
        In diesem Alter haben Sie einmalig die Möglichkeit auf einen Gesundheits-Chek-up. Die kosten werden direkt über
        ihre Krankenkassenkart abgerechnet. Manche Krankenkassen bieten alle 36 Monate eine weitere Untersuchung an,
        wenn Risikofaktoren vorliegen, die auf eine Schwächung der Gesundheit oder auf eine drohende Erkrankung
        hinweisen. Sie erhalten hier eine Privatrechnung, die zB von der Techniker Krankenkasse bis zu einem Betrag von
        35E erstattet wird.
        <div class="spacer"></div>
        <b class="orange-bold">Ab 35 Jahren:</b>
        <br><br>
        Sie können alle 3 Jahre einen Gesundheits-Check-up durchführen lassen. Wenn Sie sich in unserer Praxis als
        Patient einschreiben, können Sie die Untersuchung alle 2 Jahre durchführen lassen (Hausarztzentrierte
        Versorgung).
        <div class="spacer"></div>
        <b class="orange-bold">Ab 50 Jahren:</b>
        <br><br>
        <b>Frauen</b> können jährlich einen Schnelltest auf nicht sichtbares Blut im Stuhl durchführen lassen.
        <br><br>
        <b>Männer</b> haben wahlweise einen Anspruch auf einen Schnelltest auf nicht sichtbares Blut jährlich oder 2
        Darspiegelungen (Koloskopie) im Abstand von 10 Jahren.
        <div class="spacer"></div>
        <b class="orange-bold">Ab 55 Jahren:</b>
        <br><br>
        <b>Männer und Frauen</b> können wahlweise 2 Darmspiegelungen im Abstand von 10 Jahren
        oder anstelle dessen alle 2 Jahre einen test auf nicht sichtbares Blut um Stuhl durchführen lassen. Ab 65
        besteht der Anspruch auf eine Darmspiegelung
        Versorgung).
        <div class="spacer"></div>
        <b class="orange-bold">Ab 65 Jahren:</b>
        <br><br>
        Bei Männern wird eine Ultraschalluntersuchung der Bauchschlagader auf Erweiterungen durchgeführt.
        <div class="spacer"></div>
        Darüber hinaus bieten wir eine medizinisch sinnvolle erweiterte Vorsorgeuntersuchung (sog. Facharzt-check) an,
        die weit über das o.g. Maß hinaus geht und eine genaue Einschätzung Ihrer Gesundheit zulässt. Dieser Check-up,
        den privat Versicherte seit Jahrzehnten selbstverständlich in Anspruch nehmen, steht jedem als Selbstzahler
        frei.
        <br><br>
        Sprechen Sie uns in der Praxis darauf an, wir informieren Sie gerne über den Untersuchungsablauf und die
        entstehenden Kosten.
        <div class="spacer-end"></div>
    </div>
</div>