<div class="container">
    <div class="title">Öffnungszeiten</div>
    <div class="text">
        Um Ihnen Wartezeit zu ersparen, können Sie ab 7.30 Uhr (Donnerstags 8.00 Uhr) telefonisch einen Termin unter der
        Rufnummer 06081-59074. In dringenden Fällen können Sie die Praxis auch ohne Voranmeldung aufsuchen.
        <div class="spacer"></div>
        <b class="orange-bold">Ärztliche Sprechstunden sind:</b><br><br>
        <table>
            <tr>
                <td>Montag</td>
                <td style="padding-left: 10px;">8.00 - 13.00 Uhr, 13.30 - 15.30 Uhr</td>
            </tr>
            <div class="spacer-small"></div>
            <tr>
                <td>Dienstag</td>
                <td style="padding-left: 10px;">8.00 - 12.30 Uhr, 14.00 - 18.30 Uhr</td>
            </tr>
            <div class="spacer-small"></div>
            <tr>
                <td>Mittwoch</td>
                <td style="padding-left: 10px;">8.00 - 12.30 Uhr</td>
            </tr>
            <div class="spacer-small"></div>
            <tr>
                <td>Donnerstag</td>
                <td style="padding-left: 10px;">8.00 - 12.30 Uhr, 14.00 - 18.30 Uhr</td>
            </tr>
            <div class="spacer-small"></div>
            <tr>
                <td>Freitag</td>
                <td style="padding-left: 10px;">8.00 - 12.30 Uhr</td>
            </tr>
        </table>
        <div class="spacer"></div>
        Außerhalb unserer Sprechzeiten ist nachts und am Wochenende der ärztliche Bereitschaftsdienst in Usingen im
        neuen Krankenhaus erreichbar,
        Tel.: 116 117, zu den übrigen Zeiten wird Ihnen über unseren Ansagetext (Tel.: 06081-59074) der diensthabende
        Arzt angesagt.
        <div class="spacer"></div>
        <b>In allen sehr dringenden Fällen oder Notfällen wenden Sie sich direkt an die Leitstelle in Bad Homburg (06172
            19222) bzw. wählen die Notrufnummer 112.</b>
    </div>
    <div class="spacer-end"></div>
</div>