<div class="container">
    <div class="title">Ultraschall</div>
    <div class="text">
        <div class="spacer-small"></div>
        In unserer Praxis werden alle verfügbaren Laborleistungen angeboten. Die dazu notwendige Probengenwinnung, also
        Blutentnahme, Abstriche, Urinproben, etc. wird in unserer Praxis durchgeführt.
        <br><br>
        Untersuchungen des Blutes auf Blutbestandteile und Erkrankungen, Urin- und Stuhldiagnostik sowie
        mikrobiologische Untersuchungen können danach kurzfristig erbracht werden. Im Verbund mit einem leistungsfähigen
        und hochmodernen regionalen Großlabor sind alle Ergebnisse unter Leitung von erfahrenen Labormedizinern und
        Mikrobiologen schnell und sicher verfügbar.
        <br><br>
        Darüber hinaus können für Interessierte auf Wunsch viele Zusatzleistungen wie zum Beispiel Tumormarker,
        Blutgruppe oder ein erweitertes Vorsorgelabor-Programm (s.Check-up) durchgeführt werden.
        <div class="spacer-end"></div>
    </div>
</div>