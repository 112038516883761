<div class="container">
    <div class="title">Impressum nach §5 TMG</div>
    <div class="text">
        <div class="spacer-small"></div>
        <div class="spacer"></div>
        <div>
            Verantwortlich für diese Seiten ist:<br><br>
            Dr. med. Joachim Schnell, Facharzt für Innere Medizin<br>
            Tel.: 06081 59074<br>
            E-Mail: <a href="mailto:{{email}}">info@gesundheitsportal-dr-schnell.com</a><br><br>
            Der Posteingang wird unregelmäßig überprüft und kann nicht für (Termin-) Anfragen, Rezeptwünsche, 
            etc benutzt werden. Bitte wählen Sie hierfür ausschließlich den persönlichen Kontakt.
            Eine Beantwortung von Emails kann nicht garantiert werden.<br><br>
            <div class="spacer-small"></div>
            Berufsbezeichnung: Arzt<br>
            Facharztanerkennung für Innere Medizin (Internist) wurde in Deutschland (Universität Frankfurt/M) verliehen.<br><br>
            
            <div class="spacer-small"></div>
            
            Zuständige Ärztekammer als Aufsichtsbehörde: Landesärztekammer Hessen<br><br>
            
            Zuständige Kassenärztliche Vereinigung: KV Hessen<br><br>
            
            Es gelten die Bestimmungen der Berufsordnung für Ärzte gemäß den Bestimmungen der Bundesärztekammer:<br><br>
            
            <a href="http://www.bundesaerztekammer.de/recht/berufsrecht/muster-berufsordnung-aerzte/muster-berufsordnung/">http://www.bundesaerztekammer.de/recht/berufsrecht/muster-berufsordnung-aerzte/muster-berufsordnung/</a>
            
            <div class="spacer"></div>
            Datenschutzerklärung dieser Website:<br><br>
            
            Von uns selbst werden bis auf die von Ihnen selbst zur Verfügung gestellten Daten (s.u.) keinerlei Daten erhoben.
            Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet immer das Risiko von Daten- und Sicherheitslücken aufweist
            und nicht lückenlos vor einem Zugriff von Dritten geschützt sein kann. Ausdrücklich übernehmen wir daher auch keine Verantwortung hierüber.<br><br>
            Durch den Besuch dieser Webseite können Informationen über den Zugriff (IP Adresse anonymisiert, aufgerufene Seite bzw. URL, Statusinfomrationen 
            wie Fehlercodes,übertragene Datenmenge, Datum, Uhrzeit, Browserinformationen) gespeichert werden. 
            Diese Daten gehören nicht zu den personenbezogenen Daten, sondern sind anonymisiert. Sie werden ausschließlich zu statistischen Zwecken ausgewertet.
            Eine Weitergabe an Dritte, zu kommerziellen oder nichtkommerziellen Zwecken, findet nicht statt. 
            Dieses Internetangebot verwendet keine Techniken, die darauf abzielen, dass Zugriffsverhalten einzelner Nutzer auszuwerten. 
            Personenbezogene Nutzungsprofile werden nicht erstellt.<br><br>
            Sofern vom Nutzer persönlich freigegebene Daten (z.B. e-Mail-Adresse, eMail) zur Anwendung kommen, sind diese ausdrücklich freiwillig. 
            Die Nutzung dieser Daten ergibt sich ausschließlich aus der Nutzung und werden von uns nicht an Dritte weitergegeben.
            <div class="spacer-small"></div>
            <div class="pdf" (click)="openPdf()">Patienteninfo bezüglich der DS-GVO der EU</div>
        </div>
        <div class="spacer-end"></div>
    </div>
</div>