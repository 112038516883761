
<div class="menu-container">
    <div class="width-menu">
    <div class="icon-menu">
        <img src="/assets/images/Doctor_Medical_Instrument_Stethoscope_icon-icons.com_65902.png" height="50px" width="50px">
    </div>
    <div class="name-container">
    <span class="name-menu">HAUSARZTPRAXIS DR. SCHNELL</span>
    </div>
    <nav class="navigation" >
        <ul class="navigation-container">
            <li class="navi-point" [ngClass]="{'active-menu': menupoint==='home'}" (click)="clickMenu('home')" routerLink="/">HOME</li>
            <li class="navi-point" [ngClass]="{'active-menu': menupoint==='sprechzeiten'}" (click)="clickMenu('sprechzeiten')" routerLink="/sprechzeiten">SPRECHZEITEN</li>
            <li class="navi-point-team" [ngClass]="{'active-menu': menupoint==='team'}" (click)="clickMenu('team')" routerLink="/team">TEAM
            <div class="second-menu">
                <ul class="second-navi">
                    <li class="second-point" (click)="clickMenu('team')" routerLink="/team">Team</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('team')" routerLink="/drschnell">Dr. Schnell</li>
                </ul>
            </div></li>
            <li class="navi-point-leistungen" [ngClass]="{'active-menu': menupoint==='leistungen'}" (click)="clickMenu('leistungen')" routerLink="/leistungen">LEISTUNGEN
                <div class="second-menu">
                <ul class="second-navi">
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/vorsorge">Vorsorgeuntersuchungen</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/ekg">EKG</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/lungenfunktion">Lungenfunktion</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/impfungen">Impfungen</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/checkup">Check-Up</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/ultraschall">Ultraschall</li>
                    <li class="placeholder"></li>
                    <li class="second-point" (click)="clickMenu('leistungen')" routerLink="/labor">Laboruntersuchung</li>
                </ul>
            </div></li>
            <li class="navi-point" [ngClass]="{'active-menu': menupoint==='lehre'}" (click)="clickMenu('lehre')" routerLink="/lehre">LEHRPRAXIS</li>
            <li class="navi-point" [ngClass]="{'active-menu': menupoint==='urlaub'}"(click)="clickMenu('urlaub')" routerLink="/urlaub">URLAUB|AKTUELLES</li>
            <li class="navi-point" [ngClass]="{'active-menu': menupoint==='impressum'}" (click)="clickMenu('impressum')" routerLink="/impressum">IMPRESSUM</li>
        </ul>
    </nav>
    <div class="menu-points" (click)="openMenu()">
        <div class="point-line"></div>
    </div>
 </div>
</div>
<div class="open-menu" *ngIf="openedMenu === true">
    <div class="point" [ngClass]="{'active-menu': menupoint==='home'}" (click)="clickMenu('home')" routerLink="/">HOME</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='sprechzeiten'}" (click)="clickMenu('sprechzeiten')" routerLink="/sprechzeiten">ÖFFNUNGSZEITEN</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='team'}" (click)="clickMenu('team')" routerLink="/team">TEAM</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='praxis'}"(click)="clickMenu('praxis')" routerLink="/praxis">DIE PRAXIS</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='leistungen'}" (click)="clickMenu('leistungen')" routerLink="/leistungen">LEISTUNGEN</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='lehre'}" (click)="clickMenu('lehre')" routerLink="/lehre">LEHRPRAXIS</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='urlaub'}" (click)="clickMenu('urlaub')" routerLink="/urlaub">URLAUB|AKTUELLES</div>
    <div class="border-spacer"></div>
    <div class="point" [ngClass]="{'active-menu': menupoint==='impressum'}" (click)="clickMenu('impressum')" routerLink="/impressum">IMPRESSUM</div>
</div>
