<div class="container">
    <div class="text-container">
        <div class="welcome">
            Willkommen in der Hausarztpraxis Dr. Schnell
        </div>
        <div class="text-under-welcome">
        </div>
        <div class="text-under-welcome" *ngFor="let text of aktuellesList">
            <div [innerHTML]="text"></div>
        </div>
        <div class="image">
            <div class="image-box">
                <div class="pfeile" (click)="previousPicture()">◄</div>
                <img class="image-wid" src="{{currentPicture.path}}">
                <div class="pfeile" (click)="nextPicture()">►</div>
            </div>
        </div>
    </div>
    <div class="spacer-end"></div>
</div>