<div class="container">
    <div class="title">Dr. med. Joachim Schnell</div>
    <div class="text">
        <div class="spacer-small"></div>
        <div class="picture-box">
            <img class="picture" src="/assets/images/drschnell.JPG">
        </div>
        <div class="spacer-small"></div>
        <div>
            1987-1994 Studium der Humanmedizin an der Johann-Wolfgang-Goethe Universität Frankfurt und der Universitiy
            of Arizona, Phoenix, Az.<br><br><br>

            Studienbegleitend erfolgte die Dissertation in mehreren Forschungsjahren in den Bereichen Kardiologie und
            Nuklearmedizin über die
            hypertrophische Herzmuskelstörung bei Herrn Prof. Kaltenbach, Abschluß mit der Bestnote "summa cum
            laude".<br><br><br>

            Beginn der insgesamt siebenjährigen internistischen Ausbildung an den Städt. Kliniken Darmstadt im Bereich
            <b>Kardiologie</b>,
            danach Weiterbildung in den Fächern <b>Gastroenterologie</b> (Magen-Darmerkrankungen), <b>spezielle
                Nephrologie</b> (Nierenheilkunde),
            <b>internistische Intensivmedizin</b> und <b>Infektionserkrankungen</b>. <br><br><br>

            Darüber hinaus wurde die Zusatzqualifikation "Notfallmedizin" erworben.<br><br><br>

            Es liegt die Zulassung der Kassenärztlichen Vereinigung zur psychotherapeutischen Grundversorung sowie zur
            <a routerLink='/ultraschall  '>Ultraschalluntersuchung</a> vor.
        </div>
        <div class="spacer"></div>
        <b class="orange-bold">Mitgliedschaften</b><br>
        <ul>
            <li>Deutscher Diabtesbund</li>
            <li>Landesärztekammer Hessen</li>
            <li>Deutscher Hausärzteverband (<a href="http://hausaerzteverband.de">Hausärtzeverband</a>)</li>
            <li>Akademie für ärztliche Weiterbildung Hessen</li>

        </ul>
        <div class="spacer-end"></div>
    </div>
</div>