import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './common/menu/menu.component';
import { CommonModule } from '@angular/common';
import { HomeViewComponent } from './pages/home-view/home-view.component';
import { TeamViewComponent } from './pages/team-view/team-view.component';
import { OfficeHoursViewComponent } from './pages/office-hours-view/office-hours-view.component';
import { DrschnellViewComponent } from './pages/drschnell-view/drschnell-view.component';
import { FooterComponent } from './common/footer/footer.component';
import { ImpressumViewComponent } from './pages/impressum-view/impressum-view.component';
import { UrlaubViewComponent } from './pages/urlaub-view/urlaub-view.component';
import { LehreViewComponent } from './pages/lehre-view/lehre-view.component';
import { LeistungenViewComponent } from './pages/leistungen-view/leistungen-view.component';
import { VorsorgeViewComponent } from './pages/leistungen-view/vorsorge-view/vorsorge-view.component';
import { EkgViewComponent } from './pages/leistungen-view/ekg-view/ekg-view.component';
import { LungenViewComponent } from './pages/leistungen-view/lungen-view/lungen-view.component';
import { ImpfungenViewComponent } from './pages/leistungen-view/impfungen-view/impfungen-view.component';
import { CheckupViewComponent } from './pages/leistungen-view/checkup-view/checkup-view.component';
import { UltraschallViewComponent } from './pages/leistungen-view/ultraschall-view/ultraschall-view.component';
import { LaborViewComponent } from './pages/leistungen-view/labor-view/labor-view.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeViewComponent,
    TeamViewComponent,
    OfficeHoursViewComponent,
    DrschnellViewComponent,
    FooterComponent,
    ImpressumViewComponent,
    UrlaubViewComponent,
    LehreViewComponent,
    LeistungenViewComponent,
    VorsorgeViewComponent,
    EkgViewComponent,
    LungenViewComponent,
    ImpfungenViewComponent,
    CheckupViewComponent,
    UltraschallViewComponent,
    LaborViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
