<div class="container">
    <div class="title">Impfungen</div>
    <div class="text">
        <div class="spacer-small"></div>
        Impfungen gehören zu den wichtigsten und wirksamsten präventiven Maßnahmen, die in der Medizin zur Verfügung
        stehen. Moderne Impfstoffe sind gut verträglich und unerwünschte Arzneimittelnebenwirkungen werden nur in
        seltenen Fällen beobachtet. Unmittelbares Ziel der Impfung ist es, den Geimpften vor einer ansteckenden
        Krankheit zu schützen.
        <br><br>
        Die Impfempfehlungen des BzgA können Sie hier nachlesen:
        <a href="https://www.impfen-info.de/impfempfehlungen/" target="_blank">www.impfen-info.de/impfempfehlungen/</a>
        <br><br>
        Jede Impfung wird in Ihren Impfausweis vermerkt und zusätzlich in unserem Computersystem gespeichert, so daß
        auch nach Jahren eine lückenlose Dokumentation gewährleistet ist.
        <br><br>
        <div class="spacer-end"></div>
    </div>
</div>